var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{staticClass:"p-3",attrs:{"no-body":""}},[_c('b-row',{attrs:{"align-h":"center"}},[_c('b-col',[_c('validation-observer',{ref:"formRef"},[_c('b-form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.type')}},[_c('b-form-checkbox-group',{attrs:{"options":_vm.options.type,"value-field":"item","text-field":"name","disabled":_vm.actions.isPreview},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label":_vm.$t('globalSingular.code'),"label-for":"code"}},[_c('b-form-input',{attrs:{"id":"code","size":"sm","placeholder":_vm.$t('globalSingular.auto'),"disabled":_vm.actions.isPreview},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label":_vm.$t('apps.masterDataContact.singular.brandName')}},[_c('validation-provider',{attrs:{"name":"contact","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"brand","size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Company / Brand / Person / Contact Name","disabled":_vm.actions.isPreview},model:{value:(_vm.form.brand),callback:function ($$v) {_vm.$set(_vm.form, "brand", $$v)},expression:"form.brand"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{staticClass:"mb-1",attrs:{"label":_vm.$t('apps.masterDataContact.singular.npwp')}},[_c('validation-provider',{attrs:{"name":"NPWP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"npwp","size":"sm","state":errors.length > 0 ? false:null,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataContact.placeholder.npwp')  : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.brandNpwp),callback:function ($$v) {_vm.$set(_vm.form, "brandNpwp", $$v)},expression:"form.brandNpwp"}})]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.companyPhone')}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" +62 ")]),_c('cleave',{staticClass:"form-control",attrs:{"id":"phone","raw":false,"options":_vm.options.phone,"disabled":_vm.actions.isPreview,"placeholder":"1234 567 8900"},model:{value:(_vm.form.brandPhone),callback:function ($$v) {_vm.$set(_vm.form, "brandPhone", $$v)},expression:"form.brandPhone"}})],1)],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.companyWebsite')}},[_c('validation-provider',{attrs:{"name":"brand"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"category-code","size":"sm","state":errors.length > 0 ? false:null,"placeholder":"company.id","disabled":_vm.actions.isPreview},model:{value:(_vm.form.brandWebsite),callback:function ($$v) {_vm.$set(_vm.form, "brandWebsite", $$v)},expression:"form.brandWebsite"}})]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.companyMail')}},[_c('validation-provider',{attrs:{"name":"Company Mail","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-mail","size":"sm","state":errors.length > 0 ? false:null,"placeholder":"company@mail.com","disabled":_vm.actions.isPreview},model:{value:(_vm.form.brandEmail),callback:function ($$v) {_vm.$set(_vm.form, "brandEmail", $$v)},expression:"form.brandEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.nppkp')}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('cleave',{staticClass:"form-control",attrs:{"id":"nppkp","raw":false,"options":_vm.options.nppkp,"disabled":_vm.actions.isPreview,"placeholder":"NPPKP 1234 567 8900"},model:{value:(_vm.form.brandNppkp),callback:function ($$v) {_vm.$set(_vm.form, "brandNppkp", $$v)},expression:"form.brandNppkp"}})],1)],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.TOP')}},[_c('validation-provider',{attrs:{"name":"officeId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"inputId":"company-top","options":_vm.LOV.top,"state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":!_vm.actions.isPreview ? 'Term Of Payment' : null},model:{value:(_vm.form.termOfPayment),callback:function ($$v) {_vm.$set(_vm.form, "termOfPayment", $$v)},expression:"form.termOfPayment"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.company.plan_id > 2)?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('globalSingular.office'),"label-for":"officeId"}},[_c('validation-provider',{attrs:{"name":"officeId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"officeId","options":_vm.LOV.offices,"reduce":function (field) { return field.id; },"label":"name","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.officeId),callback:function ($$v) {_vm.$set(_vm.form, "officeId", $$v)},expression:"form.officeId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1073449437)})],1)],1):_vm._e(),(_vm.form.type.includes('CTSP') || _vm.form.type.includes('CTCS'))?_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataTax.moduleName'),"label-for":"officeId"}},[_c('validation-provider',{attrs:{"name":"taxId"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"taxId","options":_vm.LOV.taxes,"reduce":function (field) { return field.id; },"label":"name","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.taxId),callback:function ($$v) {_vm.$set(_vm.form, "taxId", $$v)},expression:"form.taxId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3305154613)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"md":"3"}},[_c('validation-provider',{attrs:{"name":"Company Mail","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.grouping')}},[_c('b-form-checkbox',{staticClass:"mr-0 custom-control-inline",attrs:{"value":true,"disabled":_vm.actions.isPreview,"state":errors.length > 0 ? false:null},model:{value:(_vm.form.isGroup),callback:function ($$v) {_vm.$set(_vm.form, "isGroup", $$v)},expression:"form.isGroup"}}),_vm._v(" "+_vm._s(_vm.$t('apps.masterDataContact.singular.isGroup'))+" ")],1)]}}])})],1),(_vm.form.isGroup)?_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.groupContact')}},[_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"region","options":_vm.LOV.groupContact,"reduce":function (field) { return field.id; },"getOptionLabel":function (option) { return option.label; },"disabled":_vm.actions.isPreview,"placeholder":"Contact Group"},on:{"option:selected":_vm.handleRegion},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('small',[_vm._v(_vm._s(option.label))])]}}],null,false,2087015391),model:{value:(_vm.form.contactParentId),callback:function ($$v) {_vm.$set(_vm.form, "contactParentId", $$v)},expression:"form.contactParentId"}})],1)],1):_vm._e()],1),_c('div',{staticClass:"mt-1 d-flex"},[_c('feather-icon',{attrs:{"icon":"UserIcon","size":"18"}}),_c('h6',{staticClass:"mb-0 ml-50"},[_vm._v(" Contact Person ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"8"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.contactName')}},[_c('validation-provider',{attrs:{"name":"contactPerson"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"category-code","size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Contact Person Name","disabled":_vm.actions.isPreview},model:{value:(_vm.form.picName),callback:function ($$v) {_vm.$set(_vm.form, "picName", $$v)},expression:"form.picName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.contactPersonPhone')}},[_c('b-input-group',{attrs:{"size":"sm"}},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_vm._v(" +62 ")]),_c('cleave',{staticClass:"form-control",attrs:{"id":"phone","raw":false,"options":_vm.options.phone,"disabled":_vm.actions.isPreview,"placeholder":"21 890 567"},model:{value:(_vm.form.picHandphone),callback:function ($$v) {_vm.$set(_vm.form, "picHandphone", $$v)},expression:"form.picHandphone"}})],1)],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.AR')}},[_c('validation-provider',{attrs:{"name":"Receivable Account","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"ar","options":_vm.LOV.accountAR,"reduce":function (field) { return field.id; },"label":"label","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataContact.placeholder.accountAR')  : null},model:{value:(_vm.form.receivableCoaMapping),callback:function ($$v) {_vm.$set(_vm.form, "receivableCoaMapping", $$v)},expression:"form.receivableCoaMapping"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.AP')}},[_c('validation-provider',{attrs:{"name":"Payable Account","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"ap","options":_vm.LOV.accountAP,"reduce":function (field) { return field.id; },"label":"label","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataContact.placeholder.accountAP')  : null},model:{value:(_vm.form.payableCoaMapping),callback:function ($$v) {_vm.$set(_vm.form, "payableCoaMapping", $$v)},expression:"form.payableCoaMapping"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('div',{staticClass:"mt-1 d-flex justify-content-between"},[_c('h6',{staticClass:"mb-0 ml-50"},[_c('feather-icon',{attrs:{"icon":"MapPinIcon","size":"18"}}),_vm._v(" "+_vm._s(_vm.$t('apps.masterDataContact.singular.billingAddress'))+" ")],1)]),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"address","size":"sm","state":errors.length > 0 ? false:null,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataContact.placeholder.billingAddressDetail')  : null,"disabled":_vm.actions.isPreview,"rows":"3"},model:{value:(_vm.form.billingAddress),callback:function ($$v) {_vm.$set(_vm.form, "billingAddress", $$v)},expression:"form.billingAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.postalCode')}},[_c('validation-provider',{attrs:{"name":"Kode POS"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-mail","size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Postal Code","disabled":_vm.actions.isPreview},model:{value:(_vm.form.billingPostalCode),callback:function ($$v) {_vm.$set(_vm.form, "billingPostalCode", $$v)},expression:"form.billingPostalCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.billingRegionCode')}},[_c('validation-provider',{attrs:{"name":"Region"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"region","options":_vm.LOV.regions,"reduce":function (field) { return field.code; },"getOptionLabel":function (option) { return option.province.name && option.name; },"state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":"City"},on:{"option:selected":_vm.handleRegion},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('small',[_vm._v(_vm._s(option.name)+" ( "+_vm._s(option.province.name)+" )")])]}}],null,true),model:{value:(_vm.form.billingRegionCode),callback:function ($$v) {_vm.$set(_vm.form, "billingRegionCode", $$v)},expression:"form.billingRegionCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.billingProvince')}},[_c('validation-provider',{attrs:{"name":"Province","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"province","options":_vm.LOV.provinces,"reduce":function (field) { return field.code; },"label":"name","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":"province"},on:{"option:selected":_vm.handleProvince},model:{value:(_vm.form.billingProvinceCode),callback:function ($$v) {_vm.$set(_vm.form, "billingProvinceCode", $$v)},expression:"form.billingProvinceCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(0, 207, 232, 0.15)'),expression:"'rgba(0, 207, 232, 0.15)'",modifiers:{"400":true}},{name:"b-toggle",rawName:"v-b-toggle.collapse-1",modifiers:{"collapse-1":true}}],attrs:{"variant":"flat-info","size":"sm"}},[_vm._v(" Advance ")])],1)],1),_c('b-collapse',{staticClass:"mt-2",attrs:{"id":"collapse-1"}},[_c('b-card',{staticClass:"mb-0 border"},[_c('b-card-text',{staticClass:"card-text"},[_c('div',{staticClass:"mt-1 d-flex"},[_c('feather-icon',{attrs:{"icon":"MapPinIcon","size":"18"}}),_c('h6',{staticClass:"mb-0 ml-50"},[_vm._v(" Billing "+_vm._s(_vm.$t('apps.masterDataContact.singular.billingAddress'))+" ")])],1),_c('b-row',{staticClass:"mt-1"},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Address"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"address","size":"sm","state":errors.length > 0 ? false:null,"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataContact.placeholder.billingAddressDetail')  : null,"disabled":_vm.actions.isPreview,"rows":"3"},model:{value:(_vm.form.shippingAddress),callback:function ($$v) {_vm.$set(_vm.form, "shippingAddress", $$v)},expression:"form.shippingAddress"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"2"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.postalCode')}},[_c('validation-provider',{attrs:{"name":"Kode POS"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"company-mail","size":"sm","state":errors.length > 0 ? false:null,"placeholder":"Postal Code","disabled":_vm.actions.isPreview},model:{value:(_vm.form.shippingPostalCode),callback:function ($$v) {_vm.$set(_vm.form, "shippingPostalCode", $$v)},expression:"form.shippingPostalCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.billingRegionCode')}},[_c('validation-provider',{attrs:{"name":"Region"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"region","options":_vm.LOV.regions,"reduce":function (field) { return field.code; },"getOptionLabel":function (option) { return option.province.name && option.name; },"state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":"City"},on:{"option:selected":_vm.handleRegion},scopedSlots:_vm._u([{key:"option",fn:function(option){return [_c('small',[_vm._v(_vm._s(option.name)+" ( "+_vm._s(option.province.name)+" )")])]}}],null,true),model:{value:(_vm.form.shippingRegionCode),callback:function ($$v) {_vm.$set(_vm.form, "shippingRegionCode", $$v)},expression:"form.shippingRegionCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{attrs:{"label":_vm.$t('apps.masterDataContact.singular.billingProvince')}},[_c('validation-provider',{attrs:{"name":"Province"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-sm",attrs:{"id":"province","options":_vm.LOV.provinces,"reduce":function (field) { return field.code; },"label":"name","state":errors.length > 0 ? false:null,"disabled":_vm.actions.isPreview,"placeholder":"province"},on:{"option:selected":_vm.handleProvince},model:{value:(_vm.form.shippingProvinceCode),callback:function ($$v) {_vm.$set(_vm.form, "shippingProvinceCode", $$v)},expression:"form.shippingProvinceCode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('div',{staticClass:"mt-1 d-flex justify-content-between"},[_c('h6',{staticClass:"mb-0 ml-50"},[_c('feather-icon',{attrs:{"icon":"TrendingUpIcon","size":"18"}}),_vm._v(" "+_vm._s(_vm.$t('apps.masterDataContact.singular.finance'))+" ")],1)]),_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('b-table',{ref:"refFormTable",staticClass:"mt-2 position-relative",attrs:{"id":"refFormTable","fixed":"","no-provider-paging":"","no-provider-filtering":"","fields":_vm.tableColumns,"items":_vm.form.bankInformation,"primary-key":"id","show-empty":""},scopedSlots:_vm._u([{key:"head(actions)",fn:function(){return [_c('feather-icon',{staticClass:"mx-auto",attrs:{"icon":"MoreVerticalIcon"}})]},proxy:true},{key:"cell(bankName)",fn:function(ref){
var index = ref.index;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataContact.placeholder.bankName')  : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.bankInformation[index].bankName),callback:function ($$v) {_vm.$set(_vm.form.bankInformation[index], "bankName", $$v)},expression:"form.bankInformation[index].bankName"}})],1)]}},{key:"cell(isContact)",fn:function(ref){
var index = ref.index;
return [_c('b-form-group',[_c('b-form-checkbox-group',{attrs:{"options":_vm.options.IsContact,"value-field":"value","text-field":"names","disabled":_vm.actions.isPreview},model:{value:(_vm.form.bankInformation[index].isContact),callback:function ($$v) {_vm.$set(_vm.form.bankInformation[index], "isContact", $$v)},expression:"form.bankInformation[index].isContact"}})],1)]}},{key:"cell(bankAccountName)",fn:function(ref){
var index = ref.index;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataContact.placeholder.bankAccountName')  : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.bankInformation[index].bankAccountName),callback:function ($$v) {_vm.$set(_vm.form.bankInformation[index], "bankAccountName", $$v)},expression:"form.bankInformation[index].bankAccountName"}})],1)]}},{key:"cell(bankOffice)",fn:function(ref){
var index = ref.index;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataContact.placeholder.bankOffice')  : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.bankInformation[index].bankOffice),callback:function ($$v) {_vm.$set(_vm.form.bankInformation[index], "bankOffice", $$v)},expression:"form.bankInformation[index].bankOffice"}})],1)]}},{key:"cell(bankAccountNumber)",fn:function(ref){
var index = ref.index;
return [_c('b-form-group',[_c('b-form-input',{attrs:{"placeholder":!_vm.actions.isPreview ? _vm.$t('apps.masterDataContact.placeholder.bankAccountNumber')  : null,"disabled":_vm.actions.isPreview},model:{value:(_vm.form.bankInformation[index].bankAccountNumber),callback:function ($$v) {_vm.$set(_vm.form.bankInformation[index], "bankAccountNumber", $$v)},expression:"form.bankInformation[index].bankAccountNumber"}})],1)]}},{key:"cell(actions)",fn:function(ref){
var index = ref.index;
return [_c('b-button',{staticClass:"btn-icon",attrs:{"variant":"flat-danger","disabled":_vm.form.bankInformation.length <= 1},on:{"click":function($event){return _vm.removeRow(index)}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)]}},(!_vm.umpetin)?{key:"custom-foot",fn:function(ref){return [_c('b-tr',[_c('b-td',{class:['pl-0', 'd-flex', 'align-items-baseline', _vm.actions.isPreview ? 'justify-content-end': 'justify-content-between']},[(!_vm.actions.isPreview)?_c('b-button',{attrs:{"variant":"flat-primary"},on:{"click":_vm.addRow}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.addMore'))+" ")],1):_vm._e()],1),_c('b-td')],1)]}}:null],null,true)})],1)],1),_c('div',{staticClass:"mt-3 d-flex flex-sm-column flex-md-row justify-content-between"},[_c('b-button',{staticClass:"mb-sm-1 mb-md-0",attrs:{"variant":_vm.actions.isPreview ? 'outline-secondary' : 'outline-primary',"to":{ name: 'apps-master-data-contact-Home-list'}}},[_c('feather-icon',{attrs:{"icon":"ArrowLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.backToList'))+" ")],1),(_vm.actions.isPreview)?_c('b-button',{staticClass:" mb-sm-1 mb-md-0",attrs:{"variant":"outline-danger"},on:{"click":_vm.handleDelete}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.delete'))+" ")],1):_vm._e(),(!_vm.actions.isPreview)?_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":_vm.handleSubmit}},[_c('feather-icon',{attrs:{"icon":"SaveIcon"}}),_vm._v(" "+_vm._s(_vm.actions.isEditMode ? _vm.$t('globalActions.update') : _vm.$t('globalActions.create'))+" ")],1):_c('b-button',{attrs:{"variant":"outline-primary"},on:{"click":_vm.changeToEdit}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_vm._v(" "+_vm._s(_vm.$t('globalActions.changeToEdit'))+" ")],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }